import { AccountSettings, Button, PasswordFieldProps } from "@aws-amplify/ui-react";
import { ChangePasswordComponents } from "@aws-amplify/ui-react/dist/types/components/AccountSettings/ChangePassword/types";
import {
  PasswordFieldComponent,
  SubmitButtonComponent,
} from "@aws-amplify/ui-react/dist/types/components/AccountSettings/types";
import { ChangePasswordDisplayText } from "@aws-amplify/ui-react/dist/types/components/AccountSettings/utils";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { noop } from "lodash";
import React, { ChangeEvent, useState } from "react";

import PasswordStrength from "./PasswordStrength";

export const TEST_ID_CHANGE_PASSWORD_GUIDANCE = "ChangePasswordGuidance";
export const TEST_ID_CHANGE_PASSWORD_BUTTON = "ChangePasswordButton";

interface ChangePasswordWrapperProps {
  children: React.ReactNode;
}

const ChangePasswordWrapper = ({
  children,
}: ChangePasswordWrapperProps): React.JSX.Element => {
  return (
    <div className="pb-2">
      <p className="mb-5" data-testid={TEST_ID_CHANGE_PASSWORD_GUIDANCE}>
        To change your password, confirm your existing password and enter your new
        password twice.
      </p>
      <div className="columns">
        <div className="column is-half">{children}</div>
      </div>
    </div>
  );
};

const ChangePasswordSuccessMessage = (): React.JSX.Element => {
  return (
    <p>
      Your password has been successfully changed.
      <FontAwesomeIcon icon={faCheck} color="green" className="ml-2" />
    </p>
  );
};

const NewPasswordField: PasswordFieldComponent = (props: Partial<PasswordFieldProps>) => {
  const [password, setPassword] = useState<string>("");
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    props.onChange?.(e);
  };
  return (
    <div>
      <AccountSettings.ChangePassword.NewPasswordField
        label="New password"
        name="newPassword"
        onChange={handleChange}
        onBlur={props.onBlur ?? noop}
      />
      <PasswordStrength password={password} />
    </div>
  );
};

const SubmitButton: SubmitButtonComponent = (props) => (
  <Button variation="primary" data-testid={TEST_ID_CHANGE_PASSWORD_BUTTON} {...props}>
    Change password
  </Button>
);

const components: ChangePasswordComponents = {
  NewPasswordField,
  SubmitButton,
};

const displayText: ChangePasswordDisplayText = {
  currentPasswordFieldLabel: "Existing password",
  confirmPasswordFieldLabel: "Retype new password",
};

const ChangePassword = (): React.JSX.Element => {
  const [changePasswordSuccess, setChangePasswordSuccess] = useState<boolean>(false);

  if (changePasswordSuccess) {
    return <ChangePasswordSuccessMessage />;
  }

  return (
    <ChangePasswordWrapper>
      <AccountSettings.ChangePassword
        components={components}
        displayText={displayText}
        onSuccess={() => setChangePasswordSuccess(true)}
      />
    </ChangePasswordWrapper>
  );
};

export default ChangePassword;
